<template>
	<div class="download-box" @click.stop="toDownload" v-show="downloadButtonShow === '10011001'">
		<span class="iconfont icon-download"></span>
		<span>下载报告</span>
		<ToPayOrMember ref="toPayOrMember" :text="text" :showPayBtn="showPayBtn" :type="type"></ToPayOrMember>
	</div>
</template>
<script>
import ToPayOrMember from "@/components/ToPayOrMember";
export default {
	name: "downloadButton",
	components: {
		ToPayOrMember,
	},
	mixins: [],
	props: {
		type: {
			type: String,
			default: "",
		},
		id: {
			type: [String, Number],
			default: "",
		},
		detailData: {
			type: Object,
			default: {},
		}, // 研报信息
	},
	data() {
		return {
			showPayBtn: true,
			src: "", // 研报地址
			numPages: "", // 总页数
			times: 1,
			yushu: 1,
			pageArray: [],
			currentPage: 1,
			nextShow: false,
			text: "",
			memberId: null,
			downloadTypeList: {
					'专题分析报告': 10011001,
					'市场分析简报': 10011002,
					'数据分析报告': 10011003,
					'中标项目信息': 10021001,
					'中标统计查询': 10021002,
					'项目信息详情': 10021003,
					'中标公告原文链接': 10021004,
					'中标高级查询': 10021005,
					'经济及行业数据': 10031001,
					'政策规划': 10031002,
					'建筑行业动态': 10031003,
					'经济数据库图表': 10031007,
					'拟建动态': 10061001,
					'中标动态': 10061002,
					'招标信息': 10061003,
					'土地交易': 10061004,
					'历史项目数据': 10071001,
				},
			downloadButtonShow: null,
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.memberId = this.$session.getUsers().memberId;
		this.getDownloadButtonShow();
	},
	methods: {
		getDownloadButtonShow() {
			let params = {
				childModuleCode: this.downloadTypeList[this.type],
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow;
			});
		},
		// 获取报告文件数据
		getReportPdf(reportId, type) {
			// type 1 预览 2 下载
			const loading = this.$loading({
				lock: true,
				text: "下载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let paramsObj=null
					let apiNameList='research'
					let apiName='getReportPdf'

			if(this.type==='市场分析简报'||this.type==='专题分析报告'||this.type==='数据分析报告'){
						paramsObj={
							reportId: this.id,
							// type:this.type==='市场分析简报'?2:this.type==='专题分析报告'?1:3
							type:2
						}
						apiNameList='research'
						apiName='getReportPdf'

					}else if(this.type==='政策规划'||this.type==='建筑行业动态'){
						paramsObj=this.type==='政策规划'?{policyPlanId: this.id}:{industryId: this.id,type:2}
						apiNameList='industry'
						apiName='downloadIndustryDynamic'
					}
			this.$api.[apiNameList]
				.[apiName](paramsObj)
				.then(res => {
					if (res.type == "application/json") {
						let that = this;
						let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
						reader.readAsText(res);
						reader.onload = function(result) {
							let resData = JSON.parse(result.target.result); // 解析对象成功，说明是json数据
							loading.close();
							if (resData.code == "10001111") {
								that.$refs.toPayOrMember.openDialog();
								that.text = resData?.msg;
							} else {
								that.$message.error(resData?.msg);
							}
						};
					} else {
						const fileType = this.detailData.filePath.split('.').pop(); // 提取文件后缀
						console.log("fileType", this.detailData.reportTitle+ "." + fileType);
						this.$utils.downloadFile(res, (this.detailData.reportTitle||this.detailData.industryTitle)+ "." + fileType);
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		toPay(reportId) {
			this.$refs.toPayOrMember.orderData = {reportId: reportId, readOrDownLoad: "60491002", reportPay: true};
			this.$refs.toPayOrMember.openDialog();
		},
		// 点击下载
		toDownload() {
			// this.$message.error("暂无下载权限");
			let paramsObj=null
					let apiName='research'
			if(this.type==='市场分析简报'||this.type==='专题分析报告'||this.type==='数据分析报告'){
						paramsObj={
							reportId: this.id,
						}
						

					}else if(this.type==='政策规划'||this.type==='建筑行业动态'){
						paramsObj=this.type==='政策规划'?{policyPlanId: this.id}:{industryDynamicId: this.id}
						apiName='industry'
						
					}
			this.$api.[apiName]
				.getReadAndDownload(paramsObj)
				.then(res => {
					let data = res.data;
					if (data.download == "10011001") {
						// 有下载权限
						this.getReportPdf(this.id, 2);
					} else {
						// 无下载权限，提示购买下载
						// this.toPay(this.id);
						this.$emit("toPay", this.id);
						// this.$refs.payModal.open = true;

						if (data.buyRead == "10011001") {
							// 购买了阅读
							this.$refs.payModal.init(2, this.detailData, "已购阅读");
						} else {
							this.$refs.payModal.init(2, this.detailData);
						}
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
	},
};
</script>
<style lang="less" scoped>
.download-box {
	margin-left: 20px;
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #616263;
	cursor: pointer;

	.iconfont {
		font-size: 24px;
		margin-right: 4px;
		color: #8e98ae;
	}
}
</style>
